// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../images/logo.svg"
import scoreImg from "../../images/score.jpg"
import flashImg from "../../images/flash.svg"
import priceTag from "../../images/price-tag.svg"
// import { ResetCSS } from '../../common/src/assets/css/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import mapImg from '../../images/dot.svg';
import { graphql, StaticQuery } from 'gatsby';
// import { render } from "react-dom"

class LeftSidebar extends React.Component {
  render(){
    return(
      <div className="left-sidebar">
        <img src={logo} alt="clientImg" className="mb-3" />
        <h3>{this.props.data.contentfulLeftSidebar.title} <a href="https://www.boekhouder.nl/" target="_blank">Boekhouder.nl</a></h3>
        {/* <ul className="client-info">
          <li><img src={flashImg} alt="flashImg" className="mr-1" />SNEL</li>
          <li><FontAwesomeIcon icon={faStar} className="mr-1"/>erkende boekhouders</li>
          <li><img src={priceTag} alt="priceTag" className="mr-1 price-tag" />gratis offertes</li>
        </ul> 
        <div className="score d-flex align-items-center">
          <b>9,8</b>
          <img src={scoreImg} alt="clientImg" />
          <img src={scoreImg} alt="clientImg" />
          <img src={scoreImg} alt="clientImg" />
          <img src={scoreImg} alt="clientImg" />
          <img src={scoreImg} alt="clientImg" />
        </div>*/}

          <div className="sidebar-footer-outer">
            <div className="sidebar-footer-inner">
                <img src={mapImg}/>
                    <div>
                        <div className="pulsatingDot"/>
                    </div>
                    <h5 className="text">
                    {this.props.data.contentfulLeftSidebar.mapImgTitle}
                    </h5>
                    <span>
                    {this.props.data.contentfulLeftSidebar.mapImgSmallTitle}
                    </span>
            </div>

          </div>
      </div>
  )
  }
   
}

LeftSidebar.propTypes = {
  data: PropTypes.object,
}


export default function data(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulLeftSidebar {
            title
            mapImgSmallTitle
            mapImgTitle
          }
        }
      `}
      render={data => <LeftSidebar data={data} {...props} />}
    />
  )
}